// material-ui
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { AccountBalanceWallet as AccountBalanceWalletIcon } from '@mui/icons-material';

// project import
import Search from './Search';
import Profile from './Profile';
// import Notification from './Notification';
import MobileSection from './MobileSection';
import { usePopover } from 'hooks/use-popover';
import BillingPopoverSubscription from 'components/BillingPopoverSubscription';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const billingPopover = usePopover();

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      <IconButton
        disableRipple
        color="secondary"
        title="Billing"
        onClick={billingPopover.handleOpen}
        ref={billingPopover.anchorRef}
        sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
      >
        <AccountBalanceWalletIcon sx={{ color: '#c4c4c4' }} />
      </IconButton>

      {/* <Notification /> */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}

      <BillingPopoverSubscription anchorEl={billingPopover.anchorRef.current} open={billingPopover.open} onClose={billingPopover.handleClose} />
    </>
  );
};

export default HeaderContent;
