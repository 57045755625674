import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
const TrainingPage = Loadable(lazy(() => import('pages/dashboard/training')));
const HomePage = Loadable(lazy(() => import('pages/homepage/dashboard')));
const TrainingScenarioPage = Loadable(lazy(() => import('pages/training/scenario')));
const CreateDefaultScenario = Loadable(lazy(() => import('pages/training/create-default-scenario')));
const EditDefaultScenario = Loadable(lazy(() => import('pages/training/edit-default-scenario')));
const TrainingHistoryPage = Loadable(lazy(() => import('pages/training/history')));
const ConversationsPage = Loadable(lazy(() => import('pages/conversations/conversations')));
const ConversationPage = Loadable(lazy(() => import('pages/conversations/conversation')));
const ConversationV2Page = Loadable(lazy(() => import('pages/conversations/conversationv2')));
const TestStreamPage = Loadable(lazy(() => import('pages/training/test')));
const TrainingResultPage = Loadable(lazy(() => import('pages/training/results')));
const NewScenarioPage = Loadable(lazy(() => import('pages/training/new-scenario')));
const SavedActionPage = Loadable(lazy(() => import('pages/savedposts')));
const CreateCharacterPage = Loadable(lazy(() => import('pages/character/createcharacter')));
const EditCharacterPage = Loadable(lazy(() => import('pages/character/editcharacter')));
// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));
const SubscriptionPage = Loadable(lazy(() => import('pages/subscription')));
const SettingsPage = Loadable(lazy(() => import('pages/settings')));
const FeedbackPage = Loadable(lazy(() => import('pages/settings/feedback')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <HomePage />
    },
    // {
    //   path: 'color',
    //   element: <Color />
    // },
    // {
    //   path: 'sample-page',
    //   element: <SamplePage />
    // },
    {
      path: 'character',
      element: <CreateCharacterPage />
    },
    {
      path: 'character/:characterId',
      element: <EditCharacterPage />
    },
    {  
      path: 'conversations',
      children: [
        {
          path: 'conversations',
          element: <ConversationsPage />
        },
        
        {
          path: 'conversation/:id',
          element: <ConversationPage />
        },
      ]
    },
    {  
      path: 'conversationsv2',
      children: [
        {
          path: 'conversations',
          element: <ConversationsPage />
        },
        {
          path: 'saved',
          element: <SavedActionPage />
        },
        {
          path: 'conversation/:id',
          element: <ConversationV2Page />
        },
      ]
    },
    {
      path: 'training',
      children: [
        {
          path: 'default',
          element: <TrainingPage />
        },
        {
          path: 'create-default-scenario',
          element: <CreateDefaultScenario />
        },
        {
          path: 'scenario',
          element: <TrainingScenarioPage />
        },
        {
          path: 'history',
          element: <TrainingHistoryPage />
        },
        {
          path: 'teststream',
          element: <TestStreamPage />
        },
        {
          path: 'results/:id',
          element: <TrainingResultPage />
        },
        {
          path: 'edit-default-scenario/:id',
          element: <EditDefaultScenario />
        },
        {
          path: 'new-scenario',
          element: <NewScenarioPage />,
        }
      ]
    },

   

    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    },
    {
      path: "subscription",
      element: <SubscriptionPage />
    },
    {
      path: 'settings',
      element: <SettingsPage />
    },
    {
      path: 'feedback',
      element: <FeedbackPage />
    }
  ]
};

export default MainRoutes;
