import React, { useContext, useEffect } from "react";
import {
  Box,
  Grid,
  Popover,
  Skeleton,
  Button,
  Stack,
  Typography,
} from "@mui/material";

import { BillingContext } from "contexts/billing-context";
import {useNavigate} from 'react-router-dom'
const BillingPopoverSubscription = (props) => {
  const navigate = useNavigate();
  const { anchorEl, open, onClose } = props;
  const {
    subscription,
    loadingSubscription,
    CheckSubscription,
  } = useContext(BillingContext);
  useEffect(() => {
    CheckSubscription();
    console.log("Chekcing subscription")
  }, []);
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 400 } }}
    >
      <Stack direction="row" mb={2} justifyContent="space-between" alignItems="center" m={1}>
        <Typography variant="h1" color="neutral.500" fontSize="1rem">
          Billing
        </Typography>
      </Stack>
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <HeadInfo />
      
        <SubscriptionBoard
          loading={loadingSubscription}
          usage={subscription && subscription.use}
          cost={subscription && (subscription.limit-subscription.use)}
          type={"conversations"}
          infoType="User"
          upDown={true}
        />
        <Button fullWidth onClick={()=>{
          navigate('/settings')
        }} variant="contained">
          Upgrade Subscription
          </Button>
      </Box>
    </Popover>
  );
};

const HeadInfo = () => {
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={3.5}>
        <Typography variant="h1" color="neutral.400" fontSize="0.8rem" mb={1}></Typography>
      </Grid> */}
      <Grid item xs={6}>
        <Typography variant="h1" color="neutral.400" fontSize="0.8rem" mb={1} textAlign="center">
          Used
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h1" color="neutral.400" fontSize="0.8rem" mb={1} textAlign="center">
          Left
        </Typography>
      </Grid>
    </Grid>
  );
};


const SubscriptionBoard = (props) => {
  const { loading, usage, cost, type,   } = props;

  return (
    <Box my={1} sx={{ "&:hover": { backgroundColor: "#c4c4c410", borderRadius: 1 } }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={3.5}>
          <Stack direction="row" alignItems="center" mb={1}>
            <Box mx={1}>{infoType == "User" ? <PersonIcon /> : <BusinessIcon />}</Box>
            <Typography variant="h6" color="gray" fontSize="0.7rem">
              {infoType}
            </Typography>
          </Stack>
        </Grid> */}
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="center" alignItems="center" mb={1}>
            {loading ? (
              <>
                <Skeleton variant="circular" width={20} height={20} sx={{ mx: 1 }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem", width: 60 }} />
              </>
            ) : (
              <>
             
                <Typography variant="h1" color="neutral.500" fontSize="0.8rem" fontWeight={900}>
                  {usage} {type}.
                </Typography>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="center" alignItems="center" mb={1}>
            {loading ? (
              <>
                <Skeleton variant="circular" width={20} height={20} sx={{ mx: 1 }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem", width: 60 }} />
              </>
            ) : (
              <>
                <Typography variant="h1" color="neutral.500" fontSize="0.8rem" fontWeight={900}>
                  {cost} {type}.
                </Typography>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};


export default BillingPopoverSubscription;