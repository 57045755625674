import { Outlet } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <GoogleOAuthProvider clientId={"415806322858-47j5pn5so0kpgvrijjiu46sdhbmp5b0o.apps.googleusercontent.com"}>

      <Outlet />
    </GoogleOAuthProvider>
  </>
);

export default MinimalLayout;
