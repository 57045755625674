import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authenticationv2/Login')));
// const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthRegisterV2 = Loadable(lazy(() => import('pages/authenticationv2/Register')));
const MobileTest = Loadable(lazy(() => import('pages/test')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'test',
      element: <MobileTest />
    },
    // {
    //   path: 'register',
    //   element: <AuthRegister />
    // },
    {
      path: 'registerv2',
      element: <AuthRegisterV2 />
    },
    {
      path: '*',
      element: <AuthLogin />
    },
    
  ]
};

export default LoginRoutes;
