import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BillingProvider } from 'contexts/billing-context';

// material-ui
import { Box, CircularProgress } from '@mui/material';
import { fetch_api } from 'utils/server';


const TrialLayout = () => {
  // const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  // is user authenticated?
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.auth);
  const getUseToken = async (use_token) => {
    if(checked)return;
    setLoading(true);
    
    const response = await fetch_api(`/api/v2/user/check-use-token/${use_token}/`);
    if (response.ok) {
      const { token, user } = await response.json();
      if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
      }
      setChecked(true);
    } else {
      navigate('/login');
    }
    setLoading(false);
  }
  useEffect( () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urltoken = urlParams.get('token');
    const use_token = urlParams.get('use_token');
    console.log('CHECK_USE_TOKEN', use_token);
    if (urltoken) {
      // set LocalStorage token
      localStorage.setItem('token', urltoken);
      setLoading(false);

    }
    if (use_token) {
      getUseToken(use_token);
    }
    else if (!isLogged) {
      navigate('/login');
      setLoading(false);
    }else{
      setLoading(false);
    }
  }, [isLogged]);

  return (
    <Box sx={{ width: '100%', height: "100%" }}>
      {loading ? <CircularProgress></CircularProgress> : <BillingProvider>
        <GoogleOAuthProvider clientId={"415806322858-47j5pn5so0kpgvrijjiu46sdhbmp5b0o.apps.googleusercontent.com"}>
          <Box sx={{ display: 'flex', width: '100%', height: "100%" }}>
            <Box component="main" sx={{ width: '100%', height: "100%", flexGrow: 1 }}>
              <Outlet />
            </Box>
          </Box>
        </GoogleOAuthProvider>
      </BillingProvider>}
    </Box>
  );
};
// http://localhost:3000/zoom/unlimited/107?use_token=cmc6qxjTDuCVQ7LRNguKPj

export default TrialLayout;