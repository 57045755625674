import React, { createContext, useState, useEffect } from 'react';
import { fetch_api } from 'utils/server';
import { format, addDays, subDays } from 'date-fns';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const BillingContext = createContext();

function BillingProvider(props) {
  // const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) :null;
  const navigate = useNavigate();
  /**    
   * @getUsageUser - brings information from 2 endpoints (recall and openai) according to the user id
   * @getUsageOrg - brings information from 2 endpoints (recall and openai) according to the org id
   */
  // const [usageUser, setUsageUser] = useState(null);
  // const [usageOrg, setUsageOrg] = useState(null);
  // const [loadingUsageUser, setLoadingUsageUser] = useState(false);
  // const [loadingUsageOrg, setLoadingUsageOrg] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const CheckSubscription = async () => {
    const options = {
      method: 'GET'
    };
    console.log("CHECK SUBSCRIPTIOn")
    setLoadingSubscription(true);
    const response = await fetch_api('/api/v2/user/check-subscription/', options);
    if(response.status === 400){
      navigate('/subscription');
    }
    const data = await response.json();
    setSubscription(data);
    setLoadingSubscription(false);

  };
  // const getUsageUser = async (startDate, endDate) => {
  //   setLoadingUsageUser(true);

  //   const url = `/api/v2/billing/users/${user.id}?start_date=${startDate}&end_date=${endDate}`;

  //   var myHeaders = new Headers();
  //   myHeaders.append('Content-Type', 'application/json');

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     redirect: 'follow'
  //   };

  //   fetch_api(url, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setUsageUser(result);
  //       setLoadingUsageUser(false);
  //     })
  //     .catch((error) => console.log('error', error));
  // };

  // const getUsageOrg = async (startDate, endDate) => {
  //   setLoadingUsageOrg(true);

  //   const url = `/api/v2/billing/organizations/1?start_date=${startDate}&end_date=${endDate}`;

  //   var myHeaders = new Headers();
  //   myHeaders.append('Content-Type', 'application/json');

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     redirect: 'follow'
  //   };

  //   fetch_api(url, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setUsageOrg(result);
  //       setLoadingUsageOrg(false);
  //     })
  //     .catch((error) => console.log('error', error));
  // };

  // const updateBilling = (startDate, endDate) => {
  //   // console.log("update billing", startDate, endDate);
  //   getUsageOrg(startDate, endDate);
  //   getUsageUser(startDate, endDate);
  // };

  // useEffect(() => {
  //   // console.log("update billing");
  //   updateBilling(dateFilter.start, dateFilter.end);
  // }, []);

  /**
   * @rangeFilter - filter to select the range of time to show the data
   */
  const currentDate = new Date();
  const initialDateFilter = {
    start: format(currentDate, 'yyyy-MM-dd'),
    end: format(addDays(currentDate, 6), 'yyyy-MM-dd')
  };
  const [rangeFilter, setRangeFilter] = useState('W');
  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  console.log(dateFilter);
  useEffect(() => {
    let startDate, endDate;

    if (rangeFilter === 'W') {
      startDate = format(subDays(currentDate, 6), 'yyyy-MM-dd');
      endDate = format(currentDate, 'yyyy-MM-dd');
    } else if (rangeFilter === 'D') {
      startDate = endDate = format(currentDate, 'yyyy-MM-dd');
    } else if (rangeFilter === 'M') {
      startDate = format(subDays(currentDate, 29), 'yyyy-MM-dd');
      endDate = format(currentDate, 'yyyy-MM-dd');
    } else if (rangeFilter === 'Y') {
      startDate = format(subDays(currentDate, 365), 'yyyy-MM-dd');
      endDate = format(currentDate, 'yyyy-MM-dd');
    }

    setDateFilter({ start: startDate, end: endDate });
    // updateBilling(startDate, endDate);
  }, [rangeFilter]);

  const rangeSelects = [
    { key: 'D', value: 'Last Day' },
    { key: 'W', value: 'Last Week' },
    { key: 'M', value: 'Last Month' },
    { key: 'Y', value: 'Last Year' }
  ];

  const values = {
    // usageUser,
    // usageOrg,
    // loadingUsageUser,
    // loadingUsageOrg,
    subscription,
    rangeSelects,
    loadingSubscription,
    rangeFilter,
    setRangeFilter,
    CheckSubscription
  };

  return <BillingContext.Provider value={values}>{props.children}</BillingContext.Provider>;
}

export { BillingContext, BillingProvider };
