import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fetch_api } from "utils/server";
// Async actions (thunks)
export const login = createAsyncThunk('auth/login', async (credentials) => {
  const { google_login } = credentials;
  if (google_login) {
    const data = credentials;
    console.log("google_login", data);
    return data;
  } else {
    console.log("testing");
    const request = await fetch_api('/api/v2/user/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials),
      redirect: 'follow'
    }, false);
    console.log("testing2", request);

    if (request.status === 400) {
      throw new Error('Unable to log in with provided credentials.');
    }
    console.log("testing2", request);

    const data = await request.json();
    console.log(data);

    return data;
  }
});

// Logout
export const logout = createAction('auth/logout');

// signup
export const register = createAsyncThunk('auth/register', async (credentials) => {
  const request = await fetch(`${process.env.REACT_APP_API_URL}/api/v2/user/users/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials),
    redirect: 'follow'
  });

  console.log(request);
  const data = await request.json();

  if (request.status === 400) {
    console.log("400 ", data, request.status);
    return { data, status: request.status };
  }

  data.password = credentials.password;
  console.log(data);

  return { data, status: request.status };
});

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  const isLogged = localStorage.getItem('isLogged');

  return token !== null && user !== null && isLogged === 'true';
};

// Initial state
const initialState = {
  isLogged: isAuthenticated(),
  user: JSON.parse(localStorage.getItem('user')) || null,
  error: null,
  token: localStorage.getItem('token') || null
};

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('isLogged');

      state.isLogged = false;
      state.user = null;
      state.token = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload: { token, id, name, email } }) => {
        const userObj = { id, name, email, token };

        state.isLogged = true;
        state.user = userObj;
        state.token = token;
        state.error = null;
        console.log("userObj", userObj);
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(userObj));
        localStorage.setItem('isLogged', state.isLogged);
      })
      .addCase(login.rejected, (state, action) => {
        state.isLogged = false;
        state.user = null;
        state.token = null;
        state.error = action.error.message;
      })
      .addCase(register.fulfilled, (state) => {
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

// Reducer export
export default authSlice.reducer;
