import { useState, useEffect } from 'react';

export function useLocalStorage(key, fallbackValue) {
  const [value, setValue] = useState(fallbackValue);
  useEffect(() => {
    const stored = sessionStorage.getItem(key);
    setValue(stored ? JSON.parse(stored) : fallbackValue);
  }, [fallbackValue, key]);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export const fetch_api = async (url, requestOptions = { method: 'GET' }, auth = true) => {
  // console.log('env', process.env);
  if (auth) {
    const token = localStorage.getItem('token');
    if (token) {
      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: `Token ${token.replace(/['"]+/g, '')}`
      };
    }else{
      // Get token from url
      const urlParams = new URLSearchParams(window.location.search);
      const urltoken = urlParams.get('token');
      if (urltoken) {
        requestOptions.headers = {
          ...requestOptions.headers,
          Authorization: `Token ${urltoken.replace(/['"]+/g, '')}`
        };
      }
    }
  }
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, requestOptions);
};
