// assets
import { DashboardOutlined, RobotOutlined, UserOutlined } from '@ant-design/icons';
import { SmartToyOutlined, GroupsOutlined, FitnessCenter, QuestionAnswer } from '@mui/icons-material';

// icons
const icons = {
  DashboardOutlined,
  RobotOutlined,
  SmartToyOutlined,
  GroupsOutlined,
  FitnessCenter,
  UserOutlined,
  QuestionAnswer
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: icons.FitnessCenter,
      breadcrumbs: false
    },
    {
      id: 'saved',
      title: 'Saved Posts',
      type: 'item',
      url: '/conversationsv2/saved',
      icon: icons.UserOutlined,
      breadcrumbs: false
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/settings',
      icon: icons.UserOutlined,
      breadcrumbs: false
    },
    {
      id: 'feedback',
      title: 'Feedback & Support',
      type: 'item',
      url: '/feedback',
      icon: icons.RobotOutlined,
      breadcrumbs: false
    },
    
  ]
};

export default dashboard;
