// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-RH4DYRGE63"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <Routes />
    </ScrollTop>
  </ThemeCustomization>
);

export default App;
