
import {  useState, useEffect } from 'react';

const useUser = () => {
    const [user, setUser] = useState({});
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')))
        console.log("useUser", localStorage.getItem('user'));
    }, [])
    const getUser = () => {
        // setUser(JSON.parse(localStorage.getItem('user')));
        return JSON.parse(localStorage.getItem('user'));
    }
    return {user, setUser, getUser }
}
export default useUser;